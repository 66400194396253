<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4">
            <v-card-text>
                <h2 class="mb-3">Recent Messages</h2>
                <v-row>
                    <template v-for="(message,index) in recentMessages">
                        <v-col cols="6" md="4" lg="3" :key="index">
                            <message-detail :message="message"></message-detail>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4">
            <v-card-text>
                <h2 class="mb-3">Messages Stats</h2>
                <message-stats></message-stats>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "message-dashboard",
    components: {
        MessageDetail: () =>
            import("@components/messages/components/MessageDetail"),
        MessageStats: () =>
            import("@components/messages/components/MessageStats"),
    },
    data() {
        return {
            item: null,
            page: {
                title: "Dashboard",
                icon: "mdi-view-dashboard",
            },
            breadcrumbs: [
                {
                    text: "Messages",
                    disabled: false,
                    to: "/messages/view",
                },
                {
                    text: "Dashboard",
                    disabled: true,
                },
            ],
        };
    },
    watch: {},
    computed: {
        ...mapGetters("Message", ["loading", "recentMessages", "stats"]),
    },
    mounted() {
        this.getRecentMessages();
        this.getStats();
    },
    methods: {
        ...mapActions("Message", ["getRecentMessages", "getStats"]),
    },
};
</script>

<style scoped>
</style>