var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c("h2", { staticClass: "mb-3" }, [_vm._v("Recent Messages")]),
              _c(
                "v-row",
                [
                  _vm._l(_vm.recentMessages, function(message, index) {
                    return [
                      _c(
                        "v-col",
                        { key: index, attrs: { cols: "6", md: "4", lg: "3" } },
                        [_c("message-detail", { attrs: { message: message } })],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c("h2", { staticClass: "mb-3" }, [_vm._v("Messages Stats")]),
              _c("message-stats")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }